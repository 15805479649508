<template>
    <div class="not-found-page">
        <div class="not-found-wrap">
            <img
                class="not-found__image"
                src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-3a1ff277-b523-4a94-adde-1471f817c29d/843f59eb-334c-48fb-8ee6-b5967cb4a1f4.png"
                alt="404"
            />
            <el-button class="not-found__button" @click="linkHome">
                Go Back
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotFound",
    methods: {
        linkHome() {
            this.$router.push("/");
        },
    },
};
</script>

<style scoped>
.not-found-page {
    position: relative;
    width: 100%;
    height: 100%;
}
.not-found-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.not-found__image {
    margin-bottom: 20px;
    width: 507px;
}
.global-mobile .not-found__image {
    width: 3.2rem;
}
</style>